
import { defineComponent, computed, PropType } from 'vue'
import AppCheckbox from '../atoms/AppCheckbox.vue'
import AppButton from '../atoms/AppButton.vue'
import { useI18n } from 'vue-i18n'
import AppIconPlaces from '../atoms/AppIconPlaces.vue'

interface Item {
  id: string
  value: string
  label: string
}

export default defineComponent({
  name: 'CheckboxListIncludeChild',
  components: {
    AppCheckbox,
    AppButton,
    AppIconPlaces,
  },
  props: {
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    modelValue: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    startChildItem: {
      type: String,
      default: '',
    },
    endChildItem: {
      type: String,
      default: '',
    },
    parentItem: {
      type: String,
      default: '',
    },
    childItems: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, context) {
    const checkedItems = computed({
      get: () => props.modelValue,
      set: (val) => context.emit('update:modelValue', val),
    })
    const i18n = useI18n()

    const checkedAll = computed(() => {
      return checkedItems.value.length === props.items.length ? 1 : 0
    })

    const labels = computed(() => {
      return [i18n.t('parts.selectAll'), i18n.t('parts.clearAll')]
    })

    const checkAll = () => {
      context.emit('update:modelValue', props.items.map((item) => item.value))
    }

    const clearAll = () => {
      context.emit('update:modelValue', [])
    }

    const onChange = (id: string, value: string) => {
      onChangeParentItem(id, value)
      onChangeChildItem(id, value)
    }

    // 親アイテム起因の操作
    const onChangeParentItem = (id: string, value: string) => {
      if (props.parentItem !== value) return

      const parentElement: HTMLInputElement | null = document.querySelector('input[id=' + id + '-' + props.parentItem + ']')
      if (!parentElement) return

      props.childItems.forEach((childItem) => {
        if (parentElement.checked && !checkedItems.value.includes(childItem)) {
          // 親がチェックされたら子もすべてチェック
          checkedItems.value.push(childItem)
        } else if (!parentElement.checked && checkedItems.value.includes(childItem)) {
          // 親が外されたら子もすべて外す
          checkedItems.value.splice(checkedItems.value.findIndex(checkedItem => checkedItem === childItem), 1)
        }
      })
    }

    // 子アイテム起因の操作
    const onChangeChildItem = (id: string, value: string) => {
      if (!props.childItems.includes(value)) return

      const parentElement: HTMLInputElement | null = document.querySelector('input[id=' + id + '-' + props.parentItem + ']')
      if (!parentElement) return

      const childElement: HTMLInputElement | null = document.querySelector('input[id=' + id + '-' + value + ']')
      if (!childElement) return

      if (!childElement.checked && parentElement.checked) {
        // 子のチェックが外れたら、親のチェックも外す
        checkedItems.value.splice(checkedItems.value.findIndex(checkedItem => checkedItem === props.parentItem), 1)
      } else if (!parentElement.checked) {
        // 親のチェックがない状態で、子すべてのチェックがある場合に、親をチェック
        const filterChildItems = props.childItems.filter((checkChildItem) => {
          const checkChildElement: HTMLInputElement | null = document.querySelector('input[id=' + id + '-' + checkChildItem + ']')
          if (!checkChildElement) return false
          return checkChildElement.checked === true
        })
        if (props.childItems.length === filterChildItems.length) {
          checkedItems.value.push(props.parentItem)
        }
      }
    }

    return {
      checkedItems,
      checkedAll,
      labels,
      checkAll,
      clearAll,
      onChange,
      startChildItemValue: computed(() => props.startChildItem),
      endChildItemValue: computed(() => props.endChildItem),
    }
  },
})
