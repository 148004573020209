export const imperialDietMaterialTypeList = [
  { value: 'A00197' },
  { value: 'A00200' },
  { value: 'A00201' },
  { value: 'A00202' },
  { value: 'A00205' },
  { value: 'A00203' },
  { value: 'A00204' },
  { value: 'A00194' },
  { value: 'A00195' },
  { value: 'A00196' },
  { value: 'A00198' },
  { value: 'A00199' },
]
